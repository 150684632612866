<template>
	<div class="branch payment">
		
		<PickerMonth
			class="picker mt-5"
			@click="pickerMonth"
		>
		</PickerMonth>
		
		<div
			class="pa-5"
		>
			<v-row	
				v-if="false"
			>
				<v-col>
					총 결제 건수
				</v-col>
				<v-col
					class="text-right"
				>
					{{ summary.totalCount | makeComma }}건
				</v-col>
			</v-row>
			
			<v-row
				class="bg-eo rounded-lg text-black font-weight-bold"
			>
				<v-col>
					결제 총액
				</v-col>
				<v-col
					class="text-right"
				>
					{{ summary.paymentTotal | makeComma }}원
				</v-col>
			</v-row>
		</div>
		
		<div
			class="pa-3 bg-white"
			
		>
			<v-card
				v-for="(item, index) in list"
				:key="index"

				elevation="0"
				class="mt-3 mb-3 card-top"
				:to="'/Branch/Payment/Daily/' + item.salesSeq + '/' + item.paymentDate + '/'"
				
				tile
			>
				<div
					v-if="item.viewDt"
					class="border-bottom2 mt-5 pb-2 text-black font-weight-bold"
				>
					{{ item.paymentDate | transDateWeek }}
				</div>
				
				<v-card-text
					class="pa-0"
				>
					<v-row>
						<v-col>
							<div class="text-black text-subtitle-1">{{ item.salesManager }}</div>
							<div class="text-subtitle-2">{{ item.salesPhoneNumber | makeTell }}</div>
						</v-col>
						<v-col>
							<span 
								class="float-right text-subtitle-1 font-weight-bold mt-5" 
								color="success2"
							>{{ item.paymentSummaryAmount | makeComma }} 원</span> 
						</v-col>
					</v-row>
				</v-card-text>
				
				<v-divider></v-divider>
			</v-card>
			
			<v-card
				v-if="items.length <= 0"
				class="mt-3 mb-6 card-top"
				elevation="0"
			>
				<div>
					<v-row
						class="pa-0 ma-0"
					>
						<v-col 
							class="text-caption pa-0 ma-0"
						>{{ search.inquiryStartDate | transDateWeek}}</v-col>
						<v-col
							cols="7"
							class="pa-0 ma-0"
						>
							<v-divider style="margin-top: 10px;"></v-divider>
						</v-col>
					</v-row>
				</div>
				<v-card-text
					class="text-center mt-10"
				>
					조회된 정보가 없습니다
				</v-card-text>
			</v-card>
		</div>
		
		<ScrollPagination
			@onScroll="nextData"
		></ScrollPagination>
		
	</div>
</template>

<script>

	import PickerMonth from '@/components/PickerMonth.vue'
	import ScrollPagination from '@/components/ScrollPagination'
	
	export default{
		mame: 'BanchPayment'
		,components: { PickerMonth, ScrollPagination }
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,props: ['user', 'callBack']
		,data: function(){
			return {
				program: {
					title: '영업점 매출'
//					,next: {
//						title: '주간'
//						,do: true
//						,callBack: 'weekly'
//					}
				}
				,totalPage: 0
				,list_date: ''
				,items: []
				,sample: [
					{
						paymentDate: '20201111'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
					,{
						paymentDate: '20201111'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
					,{
						paymentDate: '20201109'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
					,{
						paymentDate: '20201107'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
				]
				,summary: {
					totalCount: 0
					,paymentTotal: 0
				}
				,search: {
					inquiryStartDate: ''
					,inquiryEndDate: ''
					,page: 1
				}
			}
		}
		,computed: {
			list: function(){
				console.log('computed list')
				let self = this
				
				if(self.items.length <= 0){
					self.list_date = self.search.inquiryStartDate
				}
				return this.items.filter(function(item, index){
				
					if(item.paymentDate != self.list_date || !index){
						self.list_date = item.paymentDate
						item.viewDt = true
					}
					
					return item
				})
			}
		}
		,methods: {
			getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/history'
					,request_params: this.search
					,authorize: true
					,callBack: 'getDataResult'
				})
			}
			,getDataResult: function(call){
				if(call.result){
					
					if(this.search['page'] > 1){
						this.items = this.items.concat(call.items.content.content)
					}else{
						this.items = call.items.content.content
					}
					
					this.totalPage = call.items.content.totalPages
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,getSummary: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/summary'
					,request_params: this.search
					,authorize: true
					,callBack: 'getSummaryResult'
				})
			}
			,getSummaryResult: function(call){
				if(call.result){
					this.summary = call.items.content
					this.getData()
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,pickerMonth: function(call){
				console.log('branch payment pickermonth')
	
				console.log(call)
				
				this.search = {
					inquiryStartDate: call.start
					,inquiryEndDate: call.last
					,'page': 1
				}
			}
			,nextData: function(){
				
				if(this.search['page'] >= this.totalPage){
					return false
				}else{
					this.search['page']++
					this.getData()
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'weekly'){
						this.$router.push('/Branch/Payment/Weekly')
					}else if(call.name == 'getSummaryResult'){
						this.getSummaryResult(call)
					}else if(call.name == 'getDataResult'){
						this.getDataResult(call)
					}
				}
			}
			,search: {
				handler: function(call){
					console.log('watch branch payment date')
					console.log(call)
					this.getSummary()
				}
			}
		}
	}
</script>

<style>
	.branch.payment .picker {border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; padding: 0px 10px 6px;}
	.branch.payment .border-bottom2 {border-bottom: 2px solid #ddd;}
</style>